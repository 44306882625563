import React, { useEffect, useState } from "react";
import { db } from "./firebaseConfig";
import {
    collection,
    query,
    where,
    getDocs,
    updateDoc,
    doc,
} from "firebase/firestore";
import {
    Card,
    List,
    Button,
    Row,
    Col,
    Input,
    Typography,
    Divider,
    Modal,
    Select,
    Timeline,
    Collapse,
    Spin
} from "antd";
import {
    CalendarOutlined,
    ScheduleOutlined,
    EnvironmentOutlined,
    DownOutlined,
    UpOutlined,
    HeartFilled,
    UserOutlined,
    GifOutlined,
    QuestionCircleOutlined,
    HomeOutlined,
    CarOutlined,
    PushpinOutlined,
    GiftOutlined,
    SkinOutlined,
    CaretUpFilled,
    CaretDownFilled,
} from "@ant-design/icons";
import {
    desc,
    schedule,
    taxis,
    shuttleService,
    accommodations,
    qaData,
} from "./constants";
import { ReactComponent as AttendingCard } from "./attending.svg";
import { ReactComponent as AttendingCardFlipped } from "./attending_flipped.svg";
import ReactCardFlip from "react-card-flip";
import Admin from "./Admin";
import { CardSubtitle } from "react-bootstrap";

const { Title, Paragraph, Text } = Typography;

// ─────────────────────────────────────────────
// CollapsibleSection Component
// ─────────────────────────────────────────────
const CollapsibleSection = ({
    title,
    description,
    children,
    defaultOpen = false,
    icon = <HeartFilled style={{ marginRight: 8 }} />,
}) => {
    const [open, setOpen] = useState(defaultOpen);
    return (
        <>
            <Divider onClick={() => setOpen(!open)} style={{ cursor: "pointer", marginBottom: 24 }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ width: "20px", display: "inline-block" }}>
                        {icon}
                    </div>
                    <div style={{ width: "150px", display: "inline-block" }}>
                        <Text>{title}</Text>
                    </div>
                    {open ? <CaretUpFilled style={{ width: 10, marginLeft: 8 }} /> : <CaretDownFilled style={{ width: 10, marginLeft: 8 }} />}
                </div>
            </Divider>
            {open && <div style={{ marginBottom: 24 }}>
                {description && (
                    <Paragraph style={{ marginBottom: 24, marginTop: 24 }}>{description}</Paragraph>
                )}
                {children}
            </div>
            }
        </>
    );
};

// ─────────────────────────────────────────────
// Main RSVP Component
// ─────────────────────────────────────────────
const Rsvp = ({ userEmail }) => {
    const [isFlipped, setIsFlipped] = useState(true);
    const [invitees, setInvitees] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isAdmin, setIsAdmin] = useState(userEmail === 'harry@sunbear.ltd');
    const [loading, setLoading] = useState(true); // New loading state


    // Flip the save-the-date card after 2.5 seconds
    useEffect(() => {
        const timer = setTimeout(() => {
            setIsFlipped(false);
        }, 2500);
        return () => clearTimeout(timer);
    }, []);

    // Fetch invitees from Firestore based on the user email
    useEffect(() => {
        const fetchInvitees = async () => {
            const q = query(collection(db, "users"), where("emailAddress", "==", userEmail));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                const inviteesArray = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                setInvitees(inviteesArray);

                // Mark as seen
                querySnapshot.docs.forEach(async (doc) => {
                    await updateDoc(doc.ref, { inviteSeen: true });
                });
            } else {
                console.log("No invitees found for this user email: ", userEmail);
            }
            setLoading(false);
        };

        fetchInvitees();
    }, [userEmail]);

    // Update Saturday attendance
    const handleToggleAttendance = async (inviteeDocId, newCanAttend) => {
        try {
            const inviteeDocRef = doc(db, "users", inviteeDocId);
            await updateDoc(inviteeDocRef, { canAttendSaturday: newCanAttend });
            setInvitees((prev) =>
                prev.map((invitee) =>
                    invitee.id === inviteeDocId ? { ...invitee, canAttend: newCanAttend } : invitee
                )
            );
        } catch (error) {
            console.error("Error toggling Saturday attendance: ", error);
        }
    };

    // Update Sunday attendance
    const handleToggleAttendanceSunday = async (inviteeDocId, newCanAttend) => {
        try {
            const inviteeDocRef = doc(db, "users", inviteeDocId);
            await updateDoc(inviteeDocRef, { canAttendSunday: newCanAttend });
            setInvitees((prev) =>
                prev.map((invitee) =>
                    invitee.id === inviteeDocId ? { ...invitee, canAttendSunday: newCanAttend } : invitee
                )
            );
        } catch (error) {
            console.error("Error toggling Sunday attendance: ", error);
        }
    };

    // Update food allergies for an invitee when the field loses focus
    const handleFoodAllergiesBlur = async (inviteeDocId, newFoodAllergies) => {
        try {
            const inviteeDocRef = doc(db, "users", inviteeDocId);
            await updateDoc(inviteeDocRef, { foodAllergies: newFoodAllergies });
            console.log("Food allergies updated in Firestore for invitee", inviteeDocId);
        } catch (error) {
            console.error("Error updating food allergies for invitee", inviteeDocId, error);
        }
    };

    // Mark invitee(s) as having responded
    const setResponded = async () => {
        if (invitees.length > 0) {
            invitees.forEach(async (invitee) => {
                const inviteeDocRef = doc(db, "users", invitee.id);
                try {
                    await updateDoc(inviteeDocRef, { inviteResponded: true });
                    console.log("Response updated in Firestore for invitee", invitee.id);
                } catch (error) {
                    console.error("Error updating response in Firestore for invitee", invitee.id, error);
                }
            });
        }
    };

    // Handler to download the calendar file
    const handleAddToCalendar = () => {
        const link = document.createElement("a");
        link.href = `${process.env.PUBLIC_URL}/event.ics`;
        link.setAttribute("download", "HRWedding.ics");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    // Handler to open Google Maps directions
    const handleGetDirections = () => {
        const mapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
            "ME17 2AP"
        )}`;
        window.open(mapsUrl, "_blank");
    };

    // Show the thank-you modal (and mark the response in Firestore)
    const showModal = () => {
        setResponded();
        setIsModalVisible(true);
    };

    const handleModalOk = () => {
        setIsModalVisible(false);
    };

    if (isAdmin && window.location.pathname === '/admin') {
        return <Admin />
    }

    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <Card style={{ maxWidth: "800px", width: "100%" }}>
                {/* Save the Date Card Flip */}

                <ReactCardFlip isFlipped={isFlipped}>
                    <AttendingCardFlipped
                        style={{ width: '100%', margin: '0 0 20px 0', borderRadius: '10px', height: 'auto' }}
                        onClick={() => setIsFlipped((prev) => !prev)}
                    />
                    <AttendingCard
                        style={{ width: '100%', margin: '0 0 20px 0', borderRadius: '10px', height: 'auto' }}
                        onClick={() => setIsFlipped((prev) => !prev)}
                    />
                </ReactCardFlip>

                {/* <AttendingCard
                    style={{
                        width: "100%",
                        marginBottom: "20px",
                        borderRadius: "10px",
                        height: 'auto'
                    }}
                    onClick={() => setIsFlipped((prev) => !prev)}
                /> */}

                {/* <Title level={5}>Save the Date!</Title> */}
                <Paragraph style={{ marginBottom: 24 }}>
                    Join us in the Kentish countryside on Saturday 2nd August 2025 for our wedding and an informal garden party on the Sunday!
                    You can find all the details below. Please confirm that you can make it by the <b>1st of April</b>.
                </Paragraph>

                {/* Details Section */}
                {/* <Divider style={{ marginBottom: 8 }}>Details</Divider> */}
                <Row gutter={[16, 16]} align="middle" style={{ marginBottom: 24, marginTop: 24 }}>
                    <Col xs={24} sm={16}>
                        <strong>Date:</strong> Saturday 2 August & Sunday 3 August
                    </Col>
                    <Col xs={24} sm={8}>
                        <Button
                            type="default"
                            icon={<CalendarOutlined />}
                            onClick={handleAddToCalendar}
                            block
                        >
                            Add to Calendar
                        </Button>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} align="middle" style={{ marginBottom: 24, marginTop: 24 }}>
                    <Col xs={24} sm={16}>
                        <strong>Location:</strong> Masons Farm, ME17 2AP
                    </Col>
                    <Col xs={24} sm={8}>
                        <Button
                            type="default"
                            icon={<EnvironmentOutlined />}
                            onClick={handleGetDirections}
                            block
                        >
                            Get Directions
                        </Button>
                    </Col>
                </Row>

                <Divider></Divider>


                {/* Combined RSVP Response Section */}
                {loading ? (
                    <div style={{ textAlign: "center", margin: "40px 0" }}>
                        <Spin tip="Loading invitee data..." />
                    </div>
                ) : (
                    <>
                        <List
                            dataSource={invitees}
                            renderItem={(invitee) => (
                                <List.Item style={{ padding: "8px 0" }}>
                                    <Row gutter={[16, 16]} style={{ width: "100%" }}>
                                        <Col xs={24}>
                                            <strong>{invitee.name}:</strong>
                                        </Col>
                                        <Col xs={24} sm={12}>
                                            <Select
                                                value={
                                                    invitee.canAttendSaturday === undefined
                                                        ? undefined
                                                        : invitee.canAttendSaturday
                                                            ? "canAttend"
                                                            : "cannotAttend"
                                                }
                                                placeholder="Attendance for Saturday"
                                                onChange={(value) =>
                                                    handleToggleAttendance(invitee.id, value === "canAttend")
                                                }
                                                style={{ width: "100%" }}
                                            >
                                                <Select.Option value="canAttend">Will attend Saturday</Select.Option>
                                                <Select.Option value="cannotAttend">Will not attend Saturday</Select.Option>
                                            </Select>
                                        </Col>
                                        <Col xs={24} sm={12}>
                                            <Select
                                                value={
                                                    invitee.canAttendSunday === undefined
                                                        ? undefined
                                                        : invitee.canAttendSunday
                                                            ? "canAttend"
                                                            : "cannotAttend"
                                                }
                                                placeholder="Attendance for Sunday"
                                                onChange={(value) =>
                                                    handleToggleAttendanceSunday(invitee.id, value === "canAttend")
                                                }
                                                style={{ width: "100%" }}
                                            >
                                                <Select.Option value="canAttend">Will attend Sunday</Select.Option>
                                                <Select.Option value="cannotAttend">Will not attend Sunday</Select.Option>
                                            </Select>
                                        </Col>
                                        <Col xs={24} sm={24}>
                                            <Input.TextArea
                                                style={{ resize: "none" }}
                                                rows={2}
                                                placeholder="Food Allergies (if any)"
                                                value={invitee.foodAllergies || ""}
                                                onChange={(e) => {
                                                    const newVal = e.target.value;
                                                    setInvitees((prev) =>
                                                        prev.map((item) =>
                                                            item.id === invitee.id ? { ...item, foodAllergies: newVal } : item
                                                        )
                                                    );
                                                }}
                                                onBlur={() => handleFoodAllergiesBlur(invitee.id, invitee.foodAllergies)}
                                            />
                                        </Col>
                                    </Row>
                                </List.Item>
                            )}
                        />
                        <Button type="primary" onClick={showModal} style={{ width: "100%", marginBottom: 24, marginTop: 24 }}>
                            Save Response
                        </Button>
                    </>
                )}

                {/* Schedule Section */}
                <CollapsibleSection title="Schedule" icon={<ScheduleOutlined style={{ marginRight: 20 }} />}>
                    <Card title={schedule.saturday.date} style={{ backgroundColor: "#fafafa", marginBottom: 24, marginTop: 24 }}>
                        <Timeline style={{ marginBottom: 24, marginTop: 24 }}>
                            {schedule.saturday.events.map((event, idx) => (
                                <Timeline.Item
                                    key={`sat-${idx}`}
                                    color="#424245"
                                >
                                    <strong>
                                        {event.title} {event.time && (`(${event.time})`)}
                                    </strong>
                                    <Paragraph>{event.description}</Paragraph>
                                </Timeline.Item>
                            ))}
                        </Timeline>
                    </Card>

                    <Card title={schedule.sunday.date} style={{ backgroundColor: "#fafafa", marginBottom: 24, marginTop: 24 }}>
                        <Timeline style={{ marginBottom: 24, marginTop: 24 }}>
                            {schedule.sunday.events.map((event, idx) => (
                                <Timeline.Item key={`sun-${idx}`} color="#424245">
                                    <strong>
                                        {event.title} ({event.time})
                                    </strong>
                                    <Paragraph>{event.description}</Paragraph>
                                </Timeline.Item>
                            ))}
                        </Timeline>
                    </Card>
                </CollapsibleSection>

                {/* Dress Code Section */}
                <CollapsibleSection title="Dress Code" icon={<SkinOutlined style={{ marginRight: 20 }} />}>
                    <Card title={'Wedding Ceremony & Reception'} style={{ backgroundColor: "#fafafa", marginBottom: 24, marginTop: 24 }}>
                        <Row gutter={16}>
                            <Col xs={24} md={24}>
                                <Paragraph>{schedule.dressCode.wedding}</Paragraph>
                            </Col>
                        </Row>
                    </Card>
                    <Card title={'Garden Party'} style={{ backgroundColor: "#fafafa", marginBottom: 24, marginTop: 24 }}>
                        <Row gutter={16}>
                            <Col xs={24} md={24}>
                                <Paragraph>{schedule.dressCode.poolParty}</Paragraph>
                            </Col>
                        </Row>
                    </Card>
                </CollapsibleSection>

                {/* Travel Section */}
                <CollapsibleSection title="Travel" icon={<EnvironmentOutlined style={{ marginRight: 20 }} />}>
                    <Card key={`byTrain`} title="By Train" style={{ backgroundColor: "#fafafa", marginTop: 16, marginBottom: 16 }}>
                        <Row gutter={16}>
                            <Col xs={24} md={24}>
                                <Paragraph>{schedule.travel.byTrain}</Paragraph>
                            </Col>
                        </Row>
                    </Card>
                    <Card key={`byTrain`} title="By Car" style={{ backgroundColor: "#fafafa", marginTop: 16, marginBottom: 16 }}>
                        <Row gutter={16}>
                            <Col xs={24} md={24}>
                                <Paragraph>{schedule.travel.byCar}</Paragraph>
                                {/* <Paragraph>
                                    <strong>Parking Map:</strong> {schedule.travel.parkingMap}
                                </Paragraph> */}
                            </Col>
                        </Row>
                    </Card>
                </CollapsibleSection>

                {/* Taxis Section */}
                <CollapsibleSection title="Taxis" icon={<CarOutlined style={{ marginRight: 20 }} />}>
                    {taxis.map((taxi, idx) => (
                        <Card key={`taxi-${idx}`} title={taxi.name} style={{ backgroundColor: "#fafafa", marginTop: 16, marginBottom: 16 }}>
                            <Paragraph>
                                <strong>Phone:</strong> <a href={`tel:${taxi.phone}`}>{taxi.phone}</a>
                            </Paragraph>
                            <Paragraph>
                                <strong>Email:</strong> <a href={`mailTo:${taxi.email}`}>{taxi.email}</a>
                            </Paragraph>
                            <Paragraph>
                                <strong>Website:</strong>{" "}
                                <a href={taxi.website} target="_blank" rel="noopener noreferrer">
                                    {taxi.website}
                                </a>
                            </Paragraph>
                        </Card>
                    ))}
                    {/* <Card type="inner" style={{ marginBottom: 16 }}>
                        <Paragraph>{shuttleService.description}</Paragraph>
                        {shuttleService.options.map((opt, i) => (
                            <Paragraph key={i}>- {opt}</Paragraph>
                        ))}
                    </Card> */}
                </CollapsibleSection>

                {/* Accommodation Section */}
                <CollapsibleSection title="Hotels" icon={<HomeOutlined style={{ marginRight: 20 }} />}>
                    {accommodations.map((hotel, idx) => (
                        <Card key={`hotel-${idx}`} title={hotel.name} style={{ backgroundColor: "#fafafa", marginTop: 16, marginBottom: 16 }}>
                            {hotel.description && (
                                <Paragraph>
                                    <strong>Description:</strong> {hotel.description}
                                </Paragraph>
                            )}
                            {hotel.distance && (
                                <Paragraph>
                                    <strong>Distance:</strong> {hotel.distance}
                                </Paragraph>
                            )}
                            {hotel.address && (
                                <Paragraph>
                                    <strong>Address:</strong> {hotel.address}
                                </Paragraph>
                            )}
                            {hotel.website && (
                                <Paragraph>
                                    <strong>Website:</strong>{" "}
                                    <a href={hotel.website} target="_blank" rel="noopener noreferrer">
                                        {hotel.website}
                                    </a>
                                </Paragraph>
                            )}
                            {hotel.subItems &&
                                hotel.subItems.map((sub, subIdx) => (
                                    <Paragraph key={`hotel-${idx}-sub-${subIdx}`}>
                                        <strong>{sub.name}:</strong>{" "}
                                        <a href={sub.website} target="_blank" rel="noopener noreferrer">
                                            {sub.website}
                                        </a>
                                    </Paragraph>
                                ))}
                        </Card>
                    ))}
                </CollapsibleSection>

                {/* Wedding Registry Section */}
                <CollapsibleSection title="Gifts" icon={<GiftOutlined style={{ marginRight: 20 }} />}>
                    <Paragraph>{schedule.registry} <b><a target="_blank" href={schedule.registryLink}> here </a></b></Paragraph>

                </CollapsibleSection>

                {/* Q&A Section */}
                <CollapsibleSection title="Q&A" icon={<QuestionCircleOutlined style={{ marginRight: 20 }} />}>
                    <Collapse accordion>
                        {qaData.map((item, idx) => (
                            <Collapse.Panel header={item.question} key={`qa-${idx}`} style={{ backgroundColor: "#fafafa" }}>
                                <Paragraph>{item.answer}</Paragraph>
                            </Collapse.Panel>
                        ))}
                    </Collapse>
                </CollapsibleSection>

                <Modal
                    title="Thank you for your response"
                    open={isModalVisible}
                    onOk={handleModalOk}
                    onCancel={handleModalOk}
                    footer={null}
                >
                </Modal>
            </Card>
        </div>
    );
};

export default Rsvp;