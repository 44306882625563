import React, { useEffect, useState, useMemo } from "react";
import { db } from "./firebaseConfig";
import { collection, onSnapshot } from "firebase/firestore";
import { Table, Typography, Divider, Switch, Button, message, Input, Dropdown, Menu } from "antd";
import { apiAuthEndPoint } from './constants';
import { CSVLink } from "react-csv";
import { debounce } from "lodash";
import { PlusOutlined } from "@ant-design/icons";

const { Title } = Typography;

const UsersTable = () => {
    // State to store the fetched users data
    const [users, setUsers] = useState([]);
    const [columns, setColumns] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState("");
    const [optionalColumns, setOptionalColumns] = useState(() => {
        const saved = localStorage.getItem("optionalColumns");
        return saved ? JSON.parse(saved) : [];
    });
    const [allOptionalKeys, setAllOptionalKeys] = useState([]);

    // Function to copy the user id to clipboard
    const copyToClipboard = (id) => {
        navigator.clipboard.writeText(id).then(() => {
            message.success("User link copied to clipboard!");
        }).catch(() => {
            message.error("Failed to copy!");
        });
    };

    const sendEmailInvite = async (uuid) => {
        const url = window.location.host === 'localhost:3000' ? 'http://localhost:5002/api/sendrsvp' : 'https://harryandrachel.sunbear.ltd/api/sendrsvp';
        try {
            const res = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ uuid }),
            });

            const result = await res.json();
            message.success("Email sent to " + uuid);
            console.log(result);
        } catch (error) {
            console.error('Error:', error);
        }
    }

    // Function to set dynamic columns based on the keys in the Firestore documents
    const setDynamicColumns = (data) => {
        const allKeys = new Set();
        data.forEach(user => {
            Object.keys(user).forEach(key => allKeys.add(key));
        });

        const optionalKeys = Array.from(allKeys).filter(key => key !== "emailAddress" && key !== "id");
        setAllOptionalKeys(optionalKeys);

        const dynamicCols = optionalKeys.map((key) => {
            const isBoolean = data.some(user => typeof user[key] === "boolean");
            const isNumber = data.every(user => typeof user[key] === "number");

            if (isBoolean) {
                return {
                    title: key.toUpperCase(),
                    dataIndex: key,
                    key: key,
                    sorter: (a, b) => (a[key] === b[key] ? 0 : a[key] ? -1 : 1),
                    render: (value) => {
                        if (value === undefined || value === null) {
                            return "No Entry";
                        }
                        return <Switch disabled checked={value} />;
                    },
                };
            }

            if (isNumber) {
                return {
                    title: key.toUpperCase(),
                    dataIndex: key,
                    key: key,
                    sorter: (a, b) => a[key] - b[key],
                };
            }

            return {
                title: key.toUpperCase(),
                dataIndex: key,
                key: key,
                sorter: (a, b) => a[key]?.toString().localeCompare(b[key]?.toString()),
            };
        });

        const selectedOptionalCols = dynamicCols.filter(col => optionalColumns.includes(col.key));

        setColumns([
            {
                title: "Action",
                key: "action",
                render: (_, record) => (
                    <>
                        <Button style={{ margin: 5 }} onClick={() => copyToClipboard(`${apiAuthEndPoint}${record.id}`)}>
                            Copy Invite
                        </Button>
                        <Button style={{ margin: 5 }} onClick={() => sendEmailInvite(record.id)}>
                            Send Email
                        </Button>
                    </>
                ),
            },
            {
                title: "EMAIL",
                dataIndex: "emailAddress",
                key: "emailAddress",
                sorter: (a, b) => a.emailAddress?.localeCompare(b.emailAddress),
            },
            ...selectedOptionalCols
        ]);
    };

    // Function to subscribe to Firestore updates using onSnapshot
    const subscribeToUsers = () => {
        const usersCollection = collection(db, "users");

        const unsubscribe = onSnapshot(usersCollection, (snapshot) => {
            const userList = snapshot.docs.map((doc) => ({
                id: doc.id, // Include document id for key
                ...doc.data(), // Spread the document data
            }));

            // Dynamically generate columns based on the user data
            if (userList.length > 0) {
                setDynamicColumns(userList);
            }

            setUsers(userList);
            setLoading(false);
        });

        // Cleanup the subscription when the component unmounts
        return unsubscribe;
    };

    useEffect(() => {
        // Subscribe to Firestore updates when component mounts
        const unsubscribe = subscribeToUsers();

        // Unsubscribe when component unmounts
        return () => unsubscribe();
    }, []);

    const filteredUsers = useMemo(() => {
        if (!searchTerm) return users;

        const lowerTerm = searchTerm.toLowerCase();
        return users.filter(user =>
            [user.name, user.emailAddress, user.id].some(val =>
                val?.toString().toLowerCase().includes(lowerTerm)
            )
        );
    }, [searchTerm, users]);

    const handleSearch = useMemo(() => debounce((value) => {
        setSearchTerm(value);
    }, 500), []);

    useEffect(() => {
        if (users.length > 0) {
            setDynamicColumns(users);
        }
    }, [optionalColumns]);

    useEffect(() => {
        localStorage.setItem("optionalColumns", JSON.stringify(optionalColumns));
    }, [optionalColumns]);

    const columnMenu = (
        <Menu>
            {allOptionalKeys.map(key => (
                <Menu.Item key={key} onClick={() => {
                    setOptionalColumns(prev => {
                        const next = prev.includes(key)
                            ? prev.filter(k => k !== key)
                            : [...prev, key];
                        return next;
                    });
                }}>
                    <span>{optionalColumns.includes(key) ? '✅ ' : ''}{key}</span>
                </Menu.Item>
            ))}
        </Menu>
    );

    return (
        <div style={{ padding: "20px" }}>
            <Title level={2}>Users List ({users.length})</Title>
            <Divider />
            <div style={{ marginBottom: 16, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ display: 'flex', gap: '8px' }}>
                    <Input.Search
                        placeholder="Search users"
                        onChange={(e) => handleSearch(e.target.value)}
                        style={{ width: 300 }}
                        allowClear
                    />
                    <Dropdown overlay={columnMenu} placement="bottomLeft" arrow>
                        <Button icon={<PlusOutlined />}>Columns</Button>
                    </Dropdown>
                </div>
                <Button type="primary">
                    <CSVLink
                        data={filteredUsers}
                        filename={"users.csv"}
                        style={{ color: 'white' }}
                    >
                        Download CSV
                    </CSVLink>
                </Button>
            </div>
            <Table
                dataSource={filteredUsers}
                columns={columns}
                rowKey="id"
                loading={loading}
                pagination={false} // Disable pagination for simplicity, you can enable if needed
            />
        </div>
    );
};

export default UsersTable;