import React, { useState, useEffect } from "react";
import { auth, db } from "./firebaseConfig"; // Make sure to import your Firestore instance
import { onAuthStateChanged, isSignInWithEmailLink, signInWithEmailLink, signInWithCustomToken } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore"; // Import Firestore functions
import Login from "./Login";
import Rsvp from "./Rsvp";
import Attending from "./Attending";
import Admin from "./Admin";
import "./App.css";
import { ConfigProvider } from 'antd';
import { ReactComponent as Logo } from './logo.svg';

const classyTheme = {
  token: {
    colorPrimary: '#424245',
    colorLink: '#CDA34F',
    colorText: '#2E2E2E',
    colorBgContainer: '#FAF9F6',
    lineHeight: 1.6,
  },
};

const viewAdmin = false;
// new URLSearchParams(window.location.search).get('admin') === 'true';

const App = () => {
  const [userEmail, setUserEmail] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const clearQueryParams = () => {
      const url = new URL(window.location.href);
      url.search = ""; // Remove query parameters
      window.history.replaceState(null, "", url.toString()); // Replace the current history state without query params
    };

    const loginWithUUID = async (uuid) => {
      try {
        const path = window.location.host === 'localhost:3000' ? 'http://localhost:5002/api' : 'https://harryandrachel.sunbear.ltd/api/';
        const response = await fetch(`${path}/backupLogin?uuid=${encodeURIComponent(uuid)}`);
        const data = await response.json();
        console.log('response data:', data);
        if (data.token) {
          // Use the token for authentication
          await signInWithCustomToken(auth, data.token);
        }
        setLoading(false)
      } catch (error) {
        console.error("Login failed:", error);
        setLoading(false)
        return null;
      }
    }

    const fetchEmailFromUid = async (uid) => {
      try {
        const userDocRef = doc(db, "users", uid); // Look up the user document by UID
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          return userData.emailAddress; // Return the email address from the document
        } else {
          console.error("No such document!");
          return null;
        }
      } catch (error) {
        console.error("Error fetching user document: ", error);
        return null;
      }
    };

    // Check if user is already signed in
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log('user already logged in : ', user)
        setUserEmail(user.email); // User is authenticated, skip other logic
        setLoading(false); // No need to fetch email from URL or handle sign-in with email link
        clearQueryParams();
      } else {
        // Only execute the rest of the logic if user is not authenticated
        const currentUrl = window.location.href;
        const url = new URL(currentUrl);
        const params = new URLSearchParams(url.search);
        const uidEncoded = params.get('uid'); // Get the encoded UID from the URL

        const uuid = params.get('uuid');

        if (uuid) {
          loginWithUUID(uuid);
          return
        }

        if (uidEncoded) {
          const uid = atob(uidEncoded); // Decode the UID
          console.log('uid : ', uid);
          fetchEmailFromUid(uid).then((emailAddress) => {
            console.log('email address : ', emailAddress);
            if (emailAddress && isSignInWithEmailLink(auth, currentUrl)) {
              signInWithEmailLink(auth, emailAddress, window.location.href)
                .then((result) => {
                  setUserEmail(result.user.email); // Set the email from sign-in result
                  localStorage.removeItem("emailForSignIn"); // Clean up local storage if needed
                  clearQueryParams(); // Clear the query params once signed in
                  setLoading(false)
                })
                .catch((error) => {
                  console.error("Error signing in with email link", error);
                  loginWithUUID(uid);
                })
            } else {
              loginWithUUID(uid)
            }
          });
        } else {
          setLoading(false);
        }
      }
    });

    return () => unsubscribe(); // Cleanup the subscription on unmount
  }, []);

  const handleLogin = (email) => {
    setUserEmail(email);
  };

  if (viewAdmin) {
    return <Admin />
  }

  return (
    <ConfigProvider theme={classyTheme}>
      {loading ? (
        <div style={{ textAlign: 'center', minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
          <div style={{ marginBottom: '20px', position: 'relative' }} >
            <Logo style={{ width: '32px' }} className="fadeInOut" />
          </div>
        </div>
      ) : (
        <>
          {!userEmail ? (
            <Login onLogin={handleLogin} />
          ) : (<Attending userEmail={userEmail} />
          )}
        </>
      )}
    </ConfigProvider>
  );
};

export default App;